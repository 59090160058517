import React, { useState, useEffect } from 'react'
import { getLoteriesTimeTable } from '../../apis/lotery';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit'
import { CircularProgress } from '@material-ui/core'


export default function LoteryList({ isSuperAdmin, setIsEdit, setOpenModal, runRefresh }) {
  const [loading, setLoading] = useState(false)
  const [loteries, setLotery] = useState([])
  const refresh = () => {
    setLoading(true)
    getLoteriesTimeTable()
      .then((e) => setLotery(e))
      .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    if (runRefresh) refresh()
  }, [runRefresh])
  useEffect(() => {
    refresh()
  }, [])
  return <>
    {loteries.length > 0 ?
      <table>
        <thead>
          <tr>
            <th>Loteria</th>
            <th>Descripción</th>
            <th>Abreviación</th>
            <th>Estado</th>
            <th>Domingo</th>
            <th>Lunes</th>
            <th>Martes</th>
            <th>Miercoles</th>
            <th>Jueves</th>
            <th>Viernes</th>
            <th>Sábado</th>
            {isSuperAdmin && <th></th>}
          </tr>
        </thead>
        <tbody>
          {loteries.filter((lotery) => isSuperAdmin ? [false, true].includes(lotery.active) : lotery.active).map((lotery, index) => <tr key={'loteries' + index}>
            <td>{lotery.loteria}</td>
            <td>{lotery.description}</td>
            <td>{lotery.abreviacion}</td>
            <td>{lotery.active ? 'Activa' : <strong>Inactiva</strong>}</td>
            {[0, 1, 2, 3, 4, 5, 6].map((day, index) => <td key={'day' + index}>
              {lotery.loteria_dayOfWeeks.filter((dow) => dow.dayOfWeek === day)?.map((dow, index) =>
                <div key={'dow' + index + dow.dayOfWeek} className={'nowrap'}>
                  <div><span>Inicio: <strong>{dow.timeInit}</strong></span></div>
                  <div><span>Final: <strong>{dow.timeEnd}</strong></span></div>
                </div>)
              }
            </td>)}
            {isSuperAdmin && <td onClick={() => {
              setIsEdit(lotery)
              setOpenModal(true)
            }}><EditIcon /></td>
            }
          </tr>
          )}
        </tbody>
      </table>
      :
      <div className='center'>
        {loading ? <>
          <h3>Cargando Loterias</h3>
          <CircularProgress />
        </> : <>
          <h3>No hay loterias creadas</h3>
        </>}
      </div>

    }
  </>
}