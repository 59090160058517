import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Grid, CircularProgress, Collapse, TextField, InputAdornment } from '@material-ui/core';
import { getMaxMount, setMaxMountApi } from '../../apis/sale';
export default function MaxMount({ isSuperAdmin, closeModal }) {
  const [edit, setEdit] = useState(false);
  const [newMaxMount, setNewMaxMount] = useState({
    result: 0,
    isInvalid: true,
  })
  const [maxMount, setMaxMount] = useState({
    result: 0,
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const refresh = () => {
    setLoading(true)
    getMaxMount()
      .then((maxMountFromDb) => {
        setMaxMount(maxMountFromDb)
        setNewMaxMount(prev => { return { ...prev, result: maxMountFromDb.result, isInvalid: false } })
      })
      .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    refresh();
  }, [])
  const saveNewMount = () => {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Se va cambiar el monto de $ ${(maxMount.result).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')} a $ ${parseFloat(newMaxMount.result).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      showCancelButton: true,
      confirmButtonText: 'Sí, seguro',
      cancelButtonText: 'Cancelar'
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        setMaxMountApi(newMaxMount)
          .then((responseRegistrer) => {
            Swal.fire(
              'Guardado Exitosamente',
              'Quedó guardado con el monto: $ ' + parseFloat(newMaxMount.result).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
              'success'
            )
              .then((saved) => {
                if (closeModal) closeModal()
                // if (refresh) refresh()
              })
          })
          .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
      }
    })
  }
  return <>
    {loading === true ? <div className='center'>
      <h3>Cargando monto máximo configurado</h3>
      <CircularProgress />
    </div> : <>
      {maxMount.result > 0 ?
        <Grid container className='dashboard'>
          <Grid item
            className={'dashData'}
            xs={12} sm={6} md={6}
          >
            <p><strong>$ {(maxMount.result).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong></p>
            <label>{maxMount.description}</label>
          </Grid>
          {isSuperAdmin === true && <Grid item xs={12}>
            <div className='center'>
              <div className='margin_botom_10'>
                <button onClick={() => setEdit(edit => !edit)} className='button save'>{edit ? 'No Cambiar' : 'Cambiar'}</button>
              </div>
              <Collapse in={!!edit} cla>
                <TextField
                  className='margin_botom_10'
                  id={'newMaxMount'}
                  label="Nuevo monto máximo por día"
                  variant="outlined"
                  size="small"
                  type='number'
                  name={'newMaxMount'}
                  value={newMaxMount.result}
                  min={1}
                  max={1000000000}
                  onChange={(e) => {
                    setNewMaxMount({
                      isInvalid: e.target.value && !(Number(e.target.value) > 0 && Number(e.target.value) < 1000000000),
                      result: e.target.value
                    })
                  }}
                  helperText={newMaxMount.isInvalid && <div>
                    <span>Número no valido</span>
                  </div>}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                {!newMaxMount.isInvalid &&
                  <div>
                    <button onClick={saveNewMount} className='button save'>Guardar</button>
                  </div>
                }
              </Collapse>
            </div>
          </Grid>
          }
        </Grid>
        :
        <div className='center'>
          <h2>Error al cargar monto configurado</h2>
          <h4>Por favor verificar conexión, volver a cargar o contacte al admnistrador</h4>
        </div>
      }
    </>
    }
  </>
}