import axios from "axios";
export function sendRegister(data) {
  return new Promise((resolve, reject) => {
    axios.post("auth/register", data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}


export function editRegister(userId, data) {
  return new Promise((resolve, reject) => {
    axios.patch(`auth/register/${userId}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function editPassword(data) {
  return new Promise((resolve, reject) => {
    axios.patch(`auth/register/password`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getAuthForDashboard() {
  return new Promise((resolve, reject) => {
    axios.get("auth/register/dashboard")
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}