import axios from "axios";
export function setSale(data) {
  return new Promise((resolve, reject) => {
    axios.post("sales", data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getSales(date) {
  return new Promise((resolve, reject) => {
    axios.get(`sales?dateInit=${date.dateInit}&dateEnd=${date.dateEnd}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function setMaxMountApi(data) {
  return new Promise((resolve, reject) => {
    axios.patch(`sales/maxMount`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}


export function getMaxMount() {
  return new Promise((resolve, reject) => {
    axios.get(`sales/maxMount`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getRestrictedNumbers() {
  return new Promise((resolve, reject) => {
    axios.get(`sales/restrictedNumbers`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function setRestrictedNumbers(data) {
  return new Promise((resolve, reject) => {
    axios.patch(`sales/restrictedNumbers/add`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function editRestrictedNumbers(data, saleRestrictedId) {
  return new Promise((resolve, reject) => {
    axios.patch(`sales/restrictedNumbers/edit/${saleRestrictedId}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}