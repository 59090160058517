import React from 'react'
import './footer.scss'
export default function Footer() {
  return <div className="footer">
    <div>
      <span><a href="https://www.ingeniosoft.dev" target="_blank">&copy; IngenioSoft.dev</a></span>
      {/* <div><span>Desarrollado por</span></div> */}
    </div>
    <div>
    </div>
    <div><span>Todos los derechos reservados</span></div>
  </div>
}