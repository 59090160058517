import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import jwt_decode from "jwt-decode";
import ViewAdmin from "../../views/admin/admin";
import ViewPromotor from "../../views/promotor/promotor";
import Footer from "../../components/footer/footer";

export default function Home({ type }) {
  const decodedToken = jwt_decode(localStorage.getItem("user"));
  return (
    <div className="home">
      <Navbar isHome />
      <div className="body">
        {[1, 2].includes(decodedToken.roleId) ?
          <ViewAdmin
            role={decodedToken.roleId}
            countryId={decodedToken.countryId}
          />
          :
          <ViewPromotor />
        }
      </div>
      <Footer />
    </div>
  );
};

