import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { IconButton, Tooltip, CircularProgress, Grid, TextField, InputAdornment, Collapse } from '@material-ui/core'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import PhonelinkRingTwoToneIcon from '@material-ui/icons/PhonelinkRingTwoTone';
import ClearIcon from '@material-ui/icons/Clear';

import { getLoteriesValid } from '../../apis/lotery';
import { setSale } from '../../apis/sale';

import './apostar.scss'
export default function Apostar({ closeModal, refresh }) {
  const [loteryValid, setLoteryValid] = useState([])
  const [loading, setLoading] = useState(false)
  const [cel, setCel] = useState('')
  const [isCelInvalid, setIsCelInvalid] = useState(false)
  const [apuestasError, setApuestasError] = useState([{
    number: false,
    monto: false,
    lotery: false
  }])
  const [apuestas, setApuestas] = useState([{
    number: '',
    monto: '',
    montoCombinado: '',
    loteries: [],
    modalities: []
  }]);
  const close = (e) => {
    e.preventDefault();
    if (closeModal) closeModal()
  }
  const onchangeModality = (modality, index) => {
    setApuestas((prev) => prev.map((item, i) => {
      if (index === i) {
        return { ...item, modalities: item.modalities.includes(modality) ? item.modalities.filter((lot) => lot !== modality) : [...item.modalities, modality] }
      } else {
        return item;
      }
    }))
  }
  const onchange = (e, index, loteriaId = null) => {
    e.preventDefault();
    setApuestas((prev) => prev.map((item, i) => {
      if (index === i) {
        if (!loteriaId) {
          return { ...item, [e.target.name]: e.target.value };
        } else {
          return {
            ...item,
            loteries: item.loteries?.includes(loteriaId) ?
              item.loteries.filter((lot) => lot !== loteriaId)
              :
              [...item.loteries, loteriaId]
          };
        }
      } else {
        return item;
      }
    }));
  }
  const borrar = (index) => {
    setApuestas((prev) => prev.filter((item, indexPrev) => index !== indexPrev))
    setApuestasError((prev) => prev.filter((item, indexPrev) => index !== indexPrev))
  }
  useEffect(() => {
    setLoading(true)
    getLoteriesValid()
      .then((valids) => {
        setLoteryValid(valids)
      })
      .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
      .finally(() => setLoading(false))
  }, [])
  const salesText = (isSaved = false) => {
    return `<div>Se ${!isSaved ? 'va procesar' : 'procesó'} ${apuestas.length} apuesta${apuestas.length > 1 ? 's' : ''} por monto de $ ${(apuestas.reduce((a, b) => +a + (+b.monto + +b.montoCombinado) * b.loteries.length, 0)).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}</div>
    <div class="dashboard">Distribuidos así:
    <div class="tableDash" style="max-height: 65vh;"><table>
    <thead><tr><th rowspan="2" class="bordeRight_dashed">Loterias</th><th colspan="2" class="nowrap bordeRight_dashed">Cuatro cifras</th><th colspan="2" class="nowrap">Tres cifras</th></tr>
    <tr><th>Núm</th><th class="nowrap bordeRight_dashed">Monto</th><th class="nowrap">Núm</th><th class="nowrap">Monto</th></tr></thead>
    <tbody>
    ${apuestas.map((apuesta) =>
      loteryValid.filter((lotery) => apuesta.loteries.includes(lotery.loteriaId)).map((lotery) => `<tr>
        <td class="nowrap bordeRight_dashed">${lotery['loteria.description']}</td>
        ${apuesta.modalities.includes(1) ?
          `<td class="nowrap">${apuesta.number}</td><td class="nowrap bordeRight_dashed">$ ${Number(apuesta.monto).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}</td>` :
          `<td colspan="2"class="nowrap bordeRight_dashed">-</td>`
        }
        ${apuesta.modalities.includes(2) ? `<td class="nowrap">${apuesta.number.slice(-3)}</td><td class="nowrap bordeRight_dashed">$ ${Number(apuesta.montoCombinado).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}</td>` :
          `<td colspan="2" class="nowrap bordeRight_dashed">-</td>`}
        </tr>`).join('')
    ).join('')}
   </tbody></table></div></div>
   ${!isSaved ? `<div class="center">${!cel ? '<span class="error">Precaución, se está procesando una apuesta sin diligenciar número celular</span>' : '<span>Generado para el número celular: ' + cel + '</span>'}` : ''}</div>`
  }
  const onsubmit = (dataForm) => {
    dataForm.preventDefault();
    const listErrors = apuestas.map((item) => {
      return {
        number: Number(item.number) < 0 || Number(item.number) > 9999 ? 'Este número es incorrecto' : item.modalities.includes(1) && String(item.number).length !== 4 ? 'Este número no es válido para 4 cifras' : (item.modalities.includes(2) && ![3, 4].includes(String(item.number).length)) ? 'Este número no es válido para 3 cifras' : false,
        monto: item.modalities.includes(1) && (Number(item.monto) < 100 || Number(item.monto) > 50000) ? 'Este monto es inválido' : false,
        montoCombinado: item.modalities.includes(2) && (Number(item.montoCombinado) < 100 || Number(item.montoCombinado) > 50000) ? 'Este monto es inválido' : false,
        loteries: item.loteries.length < 1 ? 'Debe jugar con al menos una lotería' : false,
        modalities: item.modalities.length < 1 ? 'Debe seleccionar al menos una metodolía' : false
      }
    });
    setApuestasError(listErrors)
    if (listErrors.every((item) => Object.values(item).every((value) => value === false))) {
      Swal.fire({
        // icon: 'question',
        title: '¿Está seguro?',
        html: salesText(),
        showCancelButton: true,
        confirmButtonText: 'Sí, seguro',
        cancelButtonText: 'Cancelar'
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setSale({
            cel, apuestas: apuestas.map((apuesta) => {
              const { modalities, number, monto, montoCombinado, loteries } = apuesta;
              return {
                number,
                monto: modalities.includes(1) ? monto : 0,
                montoCombinado: modalities.includes(2) ? montoCombinado : 0,
                loteries
              }
            })
          })
            .then((responseRegistrer) => {
              Swal.fire(
                'Guardado Exitosamente',
                salesText(true) + '<div>Quedó registrado con la apuesta número: ' + responseRegistrer?.saleTransaction?.saleTransactionId + `</div><div>${cel ? 'Con el número celular: ' + cel : 'Compra realizada sin número celular'}</div>`,
                'success'
              )
                .then((saved) => {
                  if (closeModal) closeModal()
                  // if (refresh) refresh()
                })
            })
            .catch((e) => Swal.fire('Error', e.maxTope ? e.maxTope : JSON.stringify(e, null, 1), 'error'))
        }
      })
    }
  }
  return <>
    <div className='right'>Apostar</div>
    {loteryValid.length > 0 ? <>
      <div className='center'>
        <TextField
          id={'cel'}
          label="Celular"
          variant="outlined"
          size="small"
          type='number'
          name={'cel'}
          value={cel}
          onChange={(e) => {
            setIsCelInvalid(e.target.value && !new RegExp('3[0-5][0-9]{8}$').test(e.target.value))
            setCel(e.target.value.replace(/w+/g, ""))
          }}
          helperText={isCelInvalid && <div className='center'>
            <span className='error'>Número no valido</span>
          </div>}
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <PhonelinkRingTwoToneIcon />
            </InputAdornment>,
          }}
        />
      </div>
      <div className="apuesta">
        {apuestas.map((apuesta, index) => <Grid container
          key={'apuesta' + index}
          className='apuesta_item'
        >
          <Grid item xs={12}>
            <div className='flex flex_space-around margin_botom_10'>
              <div>
                <span>Apuesta # {index + 1}</span>
              </div>
              <div>
                <span>Monto: $ {((((apuesta.modalities.includes(1) ? +apuesta.monto : 0) + (apuesta.modalities.includes(2) ? +apuesta.montoCombinado : 0))) * apuesta.loteries.length).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}</span>
              </div>
              {apuestas.length > 1 && <Tooltip title={"Eliminar apuesta " + (index + 1)}>
                <ClearIcon fontSize="small" onClick={() => borrar(index)} />
              </Tooltip>}
            </div>
            <div>
              <Grid container className='apuesta_item_body'>
                {[
                  { modility: 1, label: 'Cuatro cifras' },
                  { modility: 2, label: 'Tres cifras' }
                ].map((item, indexModality) => <div key={index + indexModality}
                  className={`checkboxList ${apuesta.modalities.includes(item.modility) ? 'active' : ''}`}
                  onClick={(e) => onchangeModality(item.modility, index)}
                >
                  <span>{item.label}</span>
                </div>)}
                {apuestasError[index].modalities && <div className='center'>
                  <span className='error'>{apuestasError[index].modalities}</span>
                </div>}
                <Grid item xs={12} className='inputList'>
                  <TextField fullWidth
                    id={"number" + index}
                    label="Número"
                    variant="outlined"
                    size="small"
                    type='number'
                    autoComplete='off'
                    placeholder='Número'
                    name={'number'}
                    value={apuesta.number}
                    onChange={(e) => onchange(e, index)}
                    min={0}
                    max={9999}
                    helperText={apuestasError[index].number && <div className='center'>
                      <span className='error'>{apuestasError[index].number}</span>
                    </div>}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className='inputList'>
                  <Collapse in={apuesta.modalities.includes(1)}>
                    <TextField fullWidth
                      id={"monto" + index}
                      label="Apuesta cuatro cifras"
                      variant="outlined"
                      size="small"
                      type='number'
                      autoComplete='off'
                      // placeholder='Apuesta directa'
                      name={'monto'}
                      value={apuesta.monto}
                      onChange={(e) => onchange(e, index)}
                      min={0}
                      max={50000}
                      helperText={apuestasError[index].monto && <div className='center'>
                        <span className='error'>{apuestasError[index].monto}</span>
                      </div>}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12} sm={6} className='inputList'>
                  <Collapse in={apuesta.modalities.includes(2)}>
                    <TextField fullWidth
                      id={"montoCombinado" + index}
                      label="Apuesta tres cifras"
                      variant="outlined"
                      size="small"
                      type='number'
                      autoComplete='off'
                      // placeholder='Apuesta tres cifras'
                      name={'montoCombinado'}
                      value={apuesta.montoCombinado}
                      onChange={(e) => onchange(e, index)}
                      min={0}
                      max={50000}
                      helperText={apuestasError[index].montoCombinado && <div className='center'>
                        <span className='error'>{apuestasError[index].montoCombinado}</span>
                      </div>}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Collapse>
                </Grid>
                {loteryValid.map((lotery, indexLotery) => <div
                  key={indexLotery}
                  className={`checkboxList ${apuesta.loteries.includes(lotery.loteriaId) ? 'active' : ''}`}
                  onClick={(e) => onchange(e, index, lotery.loteriaId)}
                >
                  <span>{lotery['loteria.abreviacion']}</span>
                </div>
                )}
                {apuestasError[index].loteries && <div className='center'>
                  <span className='error'>{apuestasError[index].loteries}</span>
                </div>}
              </Grid>
            </div>
          </Grid>
        </Grid>)}
      </div>
      <div className="center">
        <Tooltip title={"Agregar una nueva apuesta"}>
          <IconButton onClick={() => {
            setApuestasError([...apuestasError, {
              number: false,
              monto: false,
              montoCombinado: false,
              loteries: false,
              modalities: false,
            }]);
            setApuestas([...apuestas, {
              number: '',
              monto: '',
              montoCombinado: '',
              loteries: apuestas.at(-1).loteries,
              modalities: apuestas.at(-1).modalities,
            }])
          }}>
            <AddCircleTwoToneIcon />
          </IconButton>
        </Tooltip>
      </div>
    </> : <div className='center'>
      {loading ? <>
        <h3>Cargando Loterias</h3>
        <CircularProgress />
      </> : <>
        <h3>No hay loterias disponibles para apostar</h3>
      </>
      }
    </div>
    }
    <div className="left">
      Total apostado: $ {(apuestas.reduce((a, b) => +a + ((b.modalities.includes(1) ? +b.monto : 0) + (b.modalities.includes(2) ? +b.montoCombinado : 0)) * b.loteries.length, 0)).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}
    </div>
    <div className="right">
      {loteryValid.length > 0 && !isCelInvalid && < button className='button save' onClick={onsubmit} >Apostar</button>}
      <button className='button' onClick={close}>Cancelar</button>
    </div>
  </>
}