import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import LoteryAdd from './loteryAdd';
import LoteryList from './loteryList';
export default function LoteryAdmin({ isSuperAdmin }) {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [runRefresh, setRunRefresh] = useState(false)
  const handleClose = () => setOpenModal(false);
  return <>
    <div className='title'>
      <span>{isSuperAdmin ? 'Control' : 'Visualización'} de Loterias</span>
    </div>
    <div className='dashboard'>
      {isSuperAdmin && <div className={'dashDates'}>
        <button className='button save'
          onClick={() => {
            setIsEdit(null)
            setOpenModal(true)
          }}
        >Agregar Nueva loteria</button>
      </div>
      }

      <div className={'tableDash'}>
        <LoteryList
          isSuperAdmin={isSuperAdmin}
          setIsEdit={setIsEdit}
          setOpenModal={setOpenModal}
          closeModal={handleClose}
          runRefresh={runRefresh}
        />
      </div>
    </div>
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-describedby="modal-addUser"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick
      maxWidth={'xs'}
      style={{ zIndex: 1000 }}
    >
      <DialogContent>
        <LoteryAdd
          closeModal={handleClose}
          isSuperAdmin={isSuperAdmin}
          isEdit={isEdit}
          setRunRefresh={setRunRefresh}
        />
      </DialogContent>
    </Dialog>
  </>
}