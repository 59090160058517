import React, { useState } from 'react';
import Swal from 'sweetalert2';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { editPassword } from '../../apis/register';
export default function ChangePassword({ closeModal }) {
  const [dataForm, setDataForm] = useState({ old: '', new1: '', new2: '' })
  const [dataFormType, setDataFormType] = useState({ old: true, new1: true, new2: true })
  const [dataFormError, setDataFormError] = useState({})
  const close = (e) => {
    e.preventDefault();
    if (closeModal) closeModal()
  }

  const onchangeType = (name) => {
    setDataFormType({ ...dataFormType, [name]: !dataFormType[name] })
  }
  const onchange = (event) => {
    event.preventDefault();
    setDataForm({ ...dataForm, [event.target.name]: event.target.value.replace(/\s/g, "") })
  }

  const onsubmit = (value) => {
    value.preventDefault();
    const listErrors = Object.entries(dataForm).map(([key, value]) => {
      return { key, error: !value ? 'Este campo es requerido' : false }
    });

    if (Object.values(listErrors).some((list) => !!list.error)) {
      setDataFormError(Object.assign({}, ...listErrors.map(item => ({ [item.key]: item.error }))))
    } else if (dataForm.new1 !== dataForm.new2) {
      Swal.fire('Error', 'La contraseña nueva no son iguales', 'error')
    } else {
      setDataFormError({})
      Swal.fire({
        title: '¿Está seguro?',
        text: `Se va cambiar la contraseña`,
        showCancelButton: true,
        confirmButtonText: 'Sí, seguro',
        cancelButtonText: 'Cancelar'
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          const { new1, old } = dataForm;
          editPassword({ new1, old })
            .then((responseRegistrer) => {
              Swal.fire(
                'Modificado Exitosamente',
                '',
                'success'
              )
                .then((saved) => {
                  if (closeModal) closeModal()
                })
            })
            .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))

        }
      })
    }
  }
  return <div className='addUser'>
    <div className="right">
      <label htmlFor="">Modificar contraseña</label>
    </div>
    <div>
      <form>
        <div className={'input'}>
          <label>Contraseña Actual</label>
          <div className='label_password'>
            <input type={dataFormType.old ? 'password' : 'text'} name="old" value={dataForm.old} onChange={onchange} autoComplete="off" className={dataFormError.old ? 'error' : ''} />
            <label onClick={() => onchangeType('old')}>{!dataFormType.old ? <VisibilityIcon /> : <VisibilityOffIcon />}</label>
          </div>
          {dataFormError.nombre && <span>{dataFormError.nombre}</span>}
        </div>
        <div className={'input'}>
          <label>Contraseña Nueva</label>
          <div className='label_password'>
            <input type={dataFormType.new1 ? 'password' : 'text'} name="new1" value={dataForm.new1} onChange={onchange} autoComplete="off" className={dataFormError.new1 ? 'error' : ''} />
            <label onClick={() => onchangeType('new1')}>{!dataFormType.new1 ? <VisibilityIcon /> : <VisibilityOffIcon />}</label>
          </div>
          {dataFormError.new1 && <span>{dataFormError.new1}</span>}
        </div>
        <div className={'input'}>
          <label>Repita la contraseña nueva</label>
          <div className='label_password'>
            <input type={dataFormType.new2 ? 'password' : 'text'} name="new2" value={dataForm.new2} onChange={onchange} autoComplete="off" className={dataFormError.new2 ? 'error' : ''} />
            <label onClick={() => onchangeType('new2')}>{!dataFormType.new2 ? <VisibilityIcon /> : <VisibilityOffIcon />}</label>
          </div>
          {dataFormError.new2 && <span>{dataFormError.new2}</span>}
        </div>
        <div className="right">
          <button className='button save' onClick={onsubmit}>Cambiar contraseña</button>
          <button className='button' onClick={close}>Cancelar</button>
        </div>
      </form>
    </div>
  </div>
}