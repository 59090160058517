import axios from "axios";
export function getLoteriesTimeTable() {
  return new Promise((resolve, reject) => {
    axios.get("loteria/timeTable")
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}
export function getLoteriesValid() {
  return new Promise((resolve, reject) => {
    axios.get("loteria/valid")
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function addLoteryApi(data) {
  return new Promise((resolve, reject) => {
    axios.post("loteria", data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function modifiedLoteryApi(data, loteriaId) {
  return new Promise((resolve, reject) => {
    axios.patch("loteria/" + loteriaId, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}