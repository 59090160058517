import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import Apostar from '../../components/apostar/apostar';
import LoteryList from '../../components/lotery/loteryList';
import Billing from '../../components/billing/billing';
// import ChangePassword from '../../components/register/changePassword';

export default function ViewPromotor(props) {
  const [menus, setMenus] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  useEffect(() => {
    setMenus([
      { title: 'Apostar', description: 'Aquí podras crear apuestas', click: 'apostar' },
      { title: 'Loterias', description: 'Aquí podras conocer las loterias y horarios vigentes', click: 'loteria' },
      { title: 'Mi Facturación', description: 'Aquí podras conocer tus números apostados', click: 'billding' },
      // { title: 'Cambiar Contraseña', description: 'Aquí podras cambiar tu contraseña y mas...', click: 'changePassword' },
    ])
  }, [])
  return <>
    <Grid container className='menu'>
      {menus.map((menu, index) => <Grid item
        xs={5} sm={3} md={2}
        key={'menu_' + index}
        className='menu_item'
        onClick={() => menu.click && setOpenModal(menu.click)}
      >
        <h3>{menu.title}</h3>
        <span>{menu.description}</span>
      </Grid>)}
    </Grid>
    <Dialog
      open={openModal !== false}
      onClose={handleClose}
      aria-describedby="modal-addUser"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick
      maxWidth={['loteria'].includes(openModal) ? 'lg' : ['billding'].includes(openModal) ? 'md' : 'xs'}
      style={{ zIndex: 1000 }}
    >
      <DialogContent>
        {openModal === 'apostar' && <Apostar closeModal={handleClose} />}
        {openModal === 'loteria' && <div className='dashboard'>
          <div className="tableDash" >
            <LoteryList closeModal={handleClose} />
          </div>
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        }
        {openModal === 'billding' && <div>
          <Billing />
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        }
        {/* {openModal === 'changePassword' && <ChangePassword
          closeModal={handleClose}
        />
        } */}
      </DialogContent>
    </Dialog>
  </>
}