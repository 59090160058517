import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit'
import { getAuthForDashboard } from '../../apis/register';
import AddUser from "./addUser";
import Swal from "sweetalert2";

export default function Register({ isSuperAdmin, countryId }) {
  const [isEdit, setIsEdit] = useState(null)
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const [adminAndPromoters, setAdminAndPromoters] = useState([]);
  const [adminAndPromotersList, setAdminAndPromotersList] = useState([]);
  const [userChildrenList, setUserChildrenList] = useState([]);
  const refresh = () => {
    getAuthForDashboard()
      .then((auth) => {
        setAdminAndPromoters(auth?.adminAndPromoters?.length > 0 ? auth?.adminAndPromoters : [])
        setAdminAndPromotersList(auth?.adminAndPromotersList?.length > 0 ? auth?.adminAndPromotersList : [])
        setUserChildrenList(auth?.userChildrenList?.length > 0 ? auth?.userChildrenList : [])
      })
      .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
  }
  useEffect(() => {
    refresh()
  }, [])
  return <>
    <div className='title'>
      <div>Control de usuarios</div>
    </div>
    <Grid container className='dashboard'>
      <Grid item
        xs={12} sm={12} md={12}
        className={'dashDates'}
      >
        <button
          className="button save"
          onClick={() => {
            setIsEdit(null)
            setOpenModal(true)
          }}>
          Agregar Nuevo {isSuperAdmin ? 'Administrador' : 'Vendedor'}
        </button>
      </Grid>
      <Grid item
        xs={12} sm={12} md={12}
        className={'dashDates'}

      >
        <Grid container className='dashboard'>
          {adminAndPromoters.map((dash, index) => <Grid item
            key={'dashdata_' + index}
            className={'dashData'}
            xs={5} sm={3} md={2}
          >
            <p><strong>{dash.value}</strong></p>
            <label>{dash.label}</label>
          </Grid>
          )}
        </Grid>
      </Grid>
      {adminAndPromotersList.length > 0 &&
        <Grid item
          xs={12} sm={12} md={12}
          className={'tableDash'}
        >
          <table>
            <caption>Cantidad de usuarios</caption>
            <thead>
              <tr>
                <th>Usuario</th>
                <th>rol</th>
                <th>Estado</th>
                <th>Usuarios Registrados</th>
              </tr>
            </thead>
            <tbody>
              {adminAndPromotersList.map((user, index) => <tr key={'adminAndPromotersList' + index}>
                <td>{user.father}</td>
                <td>{user.description}</td>
                <td>{user.state}</td>
                <td>{user.cantidad}</td>
              </tr>)}
            </tbody>
          </table>
        </Grid>
      }
      {userChildrenList.length > 0 &&
        <Grid item
          xs={12} sm={12} md={12}
          className={'tableDash'}
        >
          <table>
            <caption>Listado de usuarios</caption>
            <thead>
              <tr>
                <th>Usuario</th>
                <th>rol</th>
                <th>Estado</th>
                <th>Comision</th>
                <th>Celular</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userChildrenList.map((user, index) => <tr key={'userChildrenList' + index}>
                <td>{user.username}</td>
                <td>{user.auth_role?.description}</td>
                <td>{user.active ? 'Activo' : 'Inactivo'}</td>
                <td>{user.comision} %</td>
                <td>{user.cel}</td>
                <td onClick={() => {
                  setIsEdit(user)
                  setOpenModal(true)
                }}><EditIcon /></td>
              </tr>)}
            </tbody>
          </table>
        </Grid>
      }
    </Grid>
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-describedby="modal-addUser"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick
      maxWidth={'xs'}
      style={{ zIndex: 1000 }}
    >
      <DialogContent>
        <AddUser
          closeModal={handleClose}
          refresh={refresh}
          isSuperAdmin={isSuperAdmin}
          isEdit={isEdit}
          countryId={countryId}
        />
      </DialogContent>
    </Dialog>
  </>
}