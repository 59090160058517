import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { sendRegister, editRegister } from '../../apis/register';
import './addUser.scss';

export default function AddUser({ closeModal, refresh, isSuperAdmin, isEdit, countryId }) {
  const [dataForm, setDataForm] = useState({
    nombre: '',
    apellido: '',
    password: '',
    cel: '',
    comision: '',
    countryId: countryId ? countryId + '' : '1',
    rol: isSuperAdmin ? 'Administrador' : 'Vendedor'
  })
  const [dataFormError, setDataFormError] = useState({})
  const onchange = (event) => {
    event.preventDefault();
    setDataForm({ ...dataForm, [event.target.name]: event.target.value.replace(/\s/g, "") })
  }
  const close = (e) => {
    e.preventDefault();
    if (closeModal) closeModal()
  }
  const onsubmit = (value) => {
    value.preventDefault();
    const listErrors = Object.entries(dataForm).map(([key, value]) => {
      return {
        key, error:
          key === 'comision' && (Number(value) < 1 || Number(value)) > 100 ? 'Este valor debe estar entre 1 y 100' :
            key === 'cel' && !new RegExp('3[0-5][0-9]{8}$').test(value) ? 'Número de celular inválido' :
              !['countryId', 'comision', 'cel', 'password', 'active'].includes(key) && value && !new RegExp(`^[a-zA-Z]{1}[a-zA-Z0-9_]{3,20}$`).test(value) ? 'Minimo 4 letras' :
                !value ? isEdit !== null && ['password'].includes(key) ? false : 'Este campo es requerido'
                  : false
      }
    });
    if (Object.values(listErrors).some((list) => !!list.error)) {
      setDataFormError(Object.assign({}, ...listErrors.map(item => ({ [item.key]: item.error }))))
    } else {
      setDataFormError({})
      const { nombre, apellido, rol, cel, countryId, ...rest } = dataForm;
      Swal.fire({
        // icon: 'question',
        title: '¿Está seguro?',
        text: `Se va ${isEdit !== null ? 'editar' : 'agregar'} a ${nombre} ${apellido} como ${rol}`,// para el pais de ${countryId === '1' ? 'Colombia' : 'Perú'}`,
        showCancelButton: true,
        confirmButtonText: 'Sí, seguro',
        cancelButtonText: 'Cancelar'
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          if (isEdit !== null) {
            const { userId } = isEdit;
            editRegister(
              userId,
              {
                username: `${nombre}.${apellido}`.toLocaleLowerCase(),
                cel: Number(cel),
                ...rest
              }
            )
              .then((responseRegistrer) => {
                Swal.fire(
                  'Guardado Exitosamente',
                  'Quedó editado el username: ' + responseRegistrer.username,
                  'success'
                )
                  .then((saved) => {
                    if (closeModal) closeModal()
                    if (refresh) refresh()
                  })
              })
              .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
          } else {
            sendRegister({
              username: `${nombre}.${apellido}`.toLocaleLowerCase(),
              cel: Number(cel),
              countryId,
              ...rest
            })
              .then((responseRegistrer) => {
                Swal.fire(
                  'Guardado Exitosamente',
                  'Quedó registrado con el username: ' + responseRegistrer.username,
                  'success'
                )
                  .then((saved) => {
                    if (closeModal) closeModal()
                    if (refresh) refresh()
                  })
              })
              .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
          }
        }
      })
    }
  }
  useEffect(() => {
    if (isEdit !== null) {
      const { username, cel, comision, active, auth_role, countryId } = isEdit;
      setDataForm({
        nombre: username.split('.')[0],
        apellido: username.split('.')[1],
        password: '',
        cel,
        comision,
        active: active === false ? '0' : '1',
        countryId: countryId ? countryId + '' : '1',
        rol: auth_role.role
      })

    } else {
      setDataForm({
        countryId: countryId ? countryId + '' : '1', nombre: '', apellido: '', password: '', cel: '', comision: '', rol: isSuperAdmin ? 'Administrador' : 'Vendedor'
      })
    }
  }, [isEdit, isSuperAdmin, countryId])
  return <div className='addUser'>
    <div className="right">
      <label htmlFor="">{isEdit !== null ? 'Editar' : 'Agregar'} {isSuperAdmin ? 'Administrador' : 'Vendedor'}</label>
    </div>
    <div>
      <form>
        <div className={'input'}>
          <label>Nombre *</label>
          <input type="text" name="nombre" placeholder="Ejemplo:Andres" value={dataForm.nombre} onChange={onchange} autoComplete="off" className={dataFormError.nombre ? 'error' : ''} />
          {dataFormError.nombre && <span>{dataFormError.nombre}</span>}
        </div>
        <div className={'input'}>
          <label>Apellido *</label>
          <input type="text" name="apellido" placeholder="Ejemplo:Mejia" value={dataForm.apellido} onChange={onchange} autoComplete="off" className={dataFormError.apellido ? 'error' : ''} />
          {dataFormError.apellido && <span>{dataFormError.apellido}</span>}
        </div>
        <div className={'input'}>
          <label>Contraseña *</label>
          <input type="password" name="password" placeholder={isEdit !== null ? 'Escriba para reemplazar' : "Ejemplo:droalh*"} value={dataForm.password} onChange={onchange} autoComplete="off" className={dataFormError.password ? 'error' : ''} />
          {dataFormError.password && <span>{dataFormError.password}</span>}
        </div>
        <div className={'input'}>
          <label>Celular *</label>
          <input type="number" name="cel" placeholder="Ejemplo:3012345678" value={dataForm.cel} onChange={onchange} autoComplete="off" className={dataFormError.cel ? 'error' : ''} />
          {dataFormError.cel && <span>{dataFormError.cel}</span>}
        </div>
        <div className={'input'}>
          <label>Comisión *</label>
          <input type="number" name="comision" placeholder="Ejemplo:12" min={1} max={100} value={dataForm.comision} onChange={onchange} autoComplete="off" className={dataFormError.comision ? 'error' : ''} />
          {dataFormError.comision && <span>{dataFormError.comision}</span>}
        </div>
        {/* {isSuperAdmin === true && <div className={'input'}>
          <label>Pais *</label>
          <select name="countryId" id="countryId" value={dataForm.countryId} onChange={onchange} >
            <option value="1">Colombia</option>
            <option value="2">Perú</option>
          </select>{dataFormError.countryId && <span>{dataFormError.countryId}</span>}
        </div>} */}
        {isEdit !== null && <div className={'input'}>
          <label>Estado</label>
          <select name="active" id="active" value={dataForm.active} onChange={onchange} >
            <option value="1">Activo</option>
            <option value="0">Inactivo</option>
          </select>
          {dataFormError.active && <span>{dataFormError.active}</span>}
        </div>}
        <div className={'input'}>
          <label>Rol</label>
          <input type="text" name="rol" placeholder="Rol" value={dataForm.rol} disabled />
        </div>
        <div className="right">
          <button className='button save' onClick={onsubmit}>{isEdit !== null ? 'Editar' : 'Agregar'}</button>
          <button className='button' onClick={close}>Cancelar</button>
        </div>
      </form>
    </div>
  </div>
}