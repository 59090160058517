
import "./logo.scss";
import React from "react";
import { Link } from "react-router-dom";
import crowLogo from "../../assets/images/crown.png"
import ingeniosoftLogo from "../../assets/images/logo.png"

export default function Logo({ color, height, fontSize }) {
  return (<>
    <Link to="/" style={{ textDecoration: 'none' }} >
      <span style={{
        display: "flex",
        alignItems: "center"
      }}>
        <div>
          <img
            style={{ height: height ? height : "90px", filter: 'invert(100%)' }}
            className="logo"
            src={crowLogo}
            alt=""
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>
            <label
              translate="off"
              htmlFor=""
              style={{
                cursor: "inherit",
                color: color ? color : "white",
                fontSize: fontSize ? fontSize : "1.5em",
                whiteSpace: 'nowrap'
              }}>
              Apuestas
            </label>
          </div>
          <div>
            <label
              translate="off"
              htmlFor=""
              style={{
                cursor: "inherit",
                color: color ? color : "white",
                fontSize: fontSize ? fontSize : "1.5em",
                whiteSpace: 'nowrap'
              }}>
              El Rey
            </label>
          </div>
        </div>
      </span>
    </Link>
    <Link to="/" style={{ textDecoration: 'none' }} >
      <img
        style={{ height: height ? height : "90px" }}
        className="logo"
        src={ingeniosoftLogo}
        alt=""
      />
    </Link>
  </>
  )
}