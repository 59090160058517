import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { addLoteryApi, modifiedLoteryApi } from '../../apis/lotery';
import { IconButton, Tooltip } from '@material-ui/core'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import ClearIcon from '@material-ui/icons/Clear';

export default function LoteryAdd({ closeModal, isEdit, setRunRefresh }) {
  const [dataForm, setDataForm] = useState({
    loteria: '',
    description: '',
    abreviacion: '',
    active: 1,
  })
  const [dataFormError, setDataFormError] = useState({})
  const [daysOfWeek, setDaysOfWeek] = useState([])
  const [daysOfWeekError, setDaysOfWeekError] = useState([])
  const onchange = (event) => {
    event.preventDefault();
    setDataForm({ ...dataForm, [event.target.name]: ['abreviacion'].includes(event.target.name) ? event.target.value.replace(/\s/g, "") : event.target.value })
  }
  const onchangeDow = (e, index) => {
    e.preventDefault();
    const newArr = daysOfWeek.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setDaysOfWeek(newArr);
  }

  const close = (e) => {
    e.preventDefault();
    if (closeModal) closeModal()
  }
  const clearDayOfWeek = (index) => {
    setDaysOfWeek((prev) => prev.filter((item, indexPrev) => index !== indexPrev))
    setDaysOfWeekError((prev) => prev.filter((item, indexPrev) => index !== indexPrev))
  }
  const onsubmit = (value) => {
    value.preventDefault();
    const listErrors = Object.entries(dataForm).map(([key, value]) => {
      return {
        key, error: !['active'].includes(key) && value && !new RegExp(`^[A-z\u00C0-\u00ff]{1}[A-z\u00C0-\u00ff0-9_ ]{3,20}$`).test(value) ? 'Minimo 4 letras, maximo 20.' :
          !value ? 'Este campo es requerido' : false
      }
    });
    const listErrorsDow = daysOfWeek.map((dow, index) => {
      const dInit = String(dow.timeInit).substring(0, 5).replace(':', '');
      const dEnd = String(dow.timeEnd).substring(0, 5).replace(':', '');
      return {
        time: Number(dInit) >= Number(dEnd) ? 'La hora de inicio debe ser menor que la hora fin' : false,
        dayOfWeek: Number(dow.dayOfWeek) < 0 || Number(dow.dayOfWeek) > 6 ? 'Debes escojer un día de semana' : daysOfWeek.filter((dowList, indexList) => index !== indexList).some((dowList) => Number(dowList.dayOfWeek) === Number(dow.dayOfWeek)) ? 'Deben ser días de la semana distintos' : false
      }
    })
    setDaysOfWeekError(listErrorsDow)
    if (Object.values(listErrors).some((list) => !!list.error)) {
      setDataFormError(Object.assign({}, ...listErrors.map(item => ({ [item.key]: item.error }))))
    } else if (listErrorsDow.every((item) => Object.values(item).every((value) => value === false))) {
      setDataFormError({})
      const { loteria, ...rest } = dataForm;
      Swal.fire({
        title: '¿Está seguro?',
        text: `Se va ${isEdit !== null ? 'editar' : 'agregar'} la lotería ${loteria}`,
        showCancelButton: true,
        confirmButtonText: 'Sí, seguro',
        cancelButtonText: 'Cancelar'
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          if (isEdit === null) {
            addLoteryApi({ loteria, ...rest, daysOfWeek })
              .then((responseRegistrer) => {
                Swal.fire(
                  'Guardado Exitosamente',
                  'Quedó registrado con el nombre: ' + loteria,
                  'success'
                )
                  .then((saved) => {
                    if (closeModal) closeModal()
                    if (setRunRefresh) setRunRefresh(true)
                  })
              })
              .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
          } else {
            modifiedLoteryApi({
              loteria,
              ...rest,
              active: rest.active === '1' ? true : false,
              daysOfWeek: daysOfWeek.map((dow) => {
                const { dayOfWeekId, ...rest } = dow;
                return {
                  ...rest,
                  dayOfWeekId: dayOfWeekId ? dayOfWeekId : null,
                }
              })
            }, isEdit.loteriaId)
              .then((responseRegistrer) => {
                Swal.fire(
                  'Modificado Exitosamente',
                  '',
                  'success'
                )
                  .then((saved) => {
                    if (closeModal) closeModal()
                    if (setRunRefresh) setRunRefresh(true)
                  })
              })
              .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
          }
        }
      })
    }
  }
  useEffect(() => {
    setRunRefresh(false)
    if (isEdit !== null) {
      const { loteria, description, abreviacion, active, loteria_dayOfWeeks } = isEdit;
      setDataForm({ loteria, description, abreviacion, active: active === true ? '1' : '0' })
      setDaysOfWeekError(loteria_dayOfWeeks.map((dow) => {
        return { dayOfWeek: false, time: false }
      }))
      setDaysOfWeek(loteria_dayOfWeeks.map((dow) => {
        const { dayOfWeek, dayOfWeekId, timeEnd, timeInit } = dow;
        return { dayOfWeek, dayOfWeekId, timeEnd, timeInit }
      }))
    } else {
      setDataForm({
        loteria: '',
        description: '',
        abreviacion: '',
        active: 1,
      })
      setDaysOfWeekError([])
      setDaysOfWeek([])
    }
  }, [isEdit,setRunRefresh])
  return <div className='addUser'>
    <div className="right">
      <label htmlFor="">{isEdit !== null ? 'Editar' : 'Agregar'} Loteria</label>
    </div>
    <div>
      <form>
        <div className={'input'}>
          <label>Loteria *</label>
          <input type="text" name="loteria" placeholder="Ejemplo:Loteria1" value={dataForm.loteria} onChange={onchange} autoComplete="off" className={dataFormError.loteria ? 'error' : ''} />
          {dataFormError.loteria && <span>{dataFormError.loteria}</span>}
        </div>
        <div className={'input'}>
          <label>Descripción *</label>
          <input type="text" name="description" placeholder="Ejemplo:Loteria primera" value={dataForm.description} onChange={onchange} autoComplete="off" className={dataFormError.description ? 'error' : ''} />
          {dataFormError.description && <span>{dataFormError.description}</span>}
        </div>
        <div className={'input'}>
          <label>Abreviación *</label>
          <input type="text" name="abreviacion" placeholder="Ejemplo:lot1*" value={dataForm.abreviacion} onChange={onchange} autoComplete="off" className={dataFormError.abreviacion ? 'error' : ''} />
          {dataFormError.abreviacion && <span>{dataFormError.abreviacion}</span>}
        </div>
        {isEdit !== null && <div className={'input'}>
          <label>Estado</label>
          <select name="active" value={dataForm.active} onChange={onchange} >
            <option value="1">Activo</option>
            <option value="0">Inactivo</option>
          </select>
        </div>}
        {daysOfWeek?.map((dow, index) => <div key={'dow' + index} className={'input_dayWeek'}>
          <div>
            <select
              name={'dayOfWeek'}
              id={dow.dayOfWeek + '_' + index}
              onChange={(e) => onchangeDow(e, index)}
              defaultValue={dow.dayOfWeek || ''}
            >
              <option value={''} disabled >--seleccionar--</option>
              {[
                { value: '0', label: 'Domingo' },
                { value: '1', label: 'Lunes' },
                { value: '2', label: 'Martes' },
                { value: '3', label: 'Miercoles' },
                { value: '4', label: 'Jueves' },
                { value: '5', label: 'Viernes' },
                { value: '6', label: 'Sabado' },
              ]
                .map((day, index) => <option key={'day_options' + index} value={day.value}>{day.label}</option>)}
            </select>
            {daysOfWeekError[index].dayOfWeek && <div className='center'>
              <span className='error'>{daysOfWeekError[index].dayOfWeek}</span>
            </div>}
            <div className="input_time">
              <div>
                <label>Hora inicio</label>
                <input type="time" value={dow.timeInit} name={'timeInit'} id={'timeInit_' + index} onChange={(e) => onchangeDow(e, index)} />
              </div>
              <div>
                <label>Hora fin</label>
                <input type="time" value={dow.timeEnd} name={'timeEnd'} id={'timeEnd_' + index} onChange={(e) => onchangeDow(e, index)} />
              </div>
            </div>
            {daysOfWeekError[index].time && <div className='center'>
              <span className='error'>{daysOfWeekError[index].time}</span>
            </div>}
            <div className='input_active'>
              <select
                name={'active'}
                onChange={(e) => onchangeDow(e, index)}
                defaultValue={dow.active || ''}
              >
                <option value={1} >Activo</option>
                <option value={0} >Inactivo</option>
              </select>
            </div>
          </div>
          <Tooltip title={"Borrar día " + (index + 1)}>
            <ClearIcon onClick={() => clearDayOfWeek(index)} />
          </Tooltip>
        </div>
        )}
        {daysOfWeek.length < 7 &&
          <div className="center">
            <Tooltip title={"Agregar nuevo día"}>
              <IconButton onClick={() => {
                setDaysOfWeekError([...daysOfWeekError, {
                  time: false, dayOfWeek: false
                }])
                setDaysOfWeek([...daysOfWeek, {
                  dayOfWeek: '0',
                  timeInit: '00:00',
                  timeEnd: '22:00',
                  active: 1,
                  dayOfWeekId: 0
                }])
              }}>
                <AddCircleTwoToneIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
        <div className="right">
          <button className='button save' onClick={onsubmit}>{isEdit !== null ? 'Editar' : 'Agregar'}</button>
          <button className='button' onClick={close}>Cancelar</button>
        </div>
      </form>
    </div>
  </div>
}