import { loginFailure, loginStart, loginSuccess } from "./AuthActions";
import { postLogin } from "../../apis/login";
import Swal from 'sweetalert2';
export const login = (user, dispatch) => {
  dispatch(loginStart());
  postLogin(user)
    .then((res) => {
      res.auth === true && dispatch(loginSuccess(res.token));
    })
    .catch((err) => {
      dispatch(loginFailure())
      // alert(JSON.stringify(err))
      Swal.fire('Error', JSON.stringify(err), 'error')
    })
};
