import { useContext, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { Notifications } from "@material-ui/icons";
import "./navbar.scss";
import { AuthContext } from "../../context/authContext/AuthContext";
import { logout } from "../../context//authContext/AuthActions";
import Logo from "../logo/Logo";
import Avatar from "../avatar/Avatar";

export default function Navbar({ isHome }) {

  const [isScrolled, setIsScrolled] = useState(false);
  const { dispatch } = useContext(AuthContext);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  return (<div className={isScrolled ? "navbar scrolled" : "navbar"}>
    <Grid container spacing={2} justifyContent="space-around" alignItems="center">
      <Grid item xs={isHome ? 12 : 8} sm={isHome ? 3 : 6} md={isHome ? 3 : 6}>
        <Logo height={isScrolled ? '50px' : undefined} />
      </Grid>
      {!isScrolled && <>
        <Grid item xs={isHome ? 12 : 4} sm={isHome ? 2 : 6} md={isHome ? 3 : 6} className="usernameInfo">
          <Notifications className="icon" style={{ verticalAlign: 'middle' }} />
          <Avatar showName />
          <div className="logout">
            <button onClick={() => dispatch(logout())} style={{ cursor: 'pointer' }}>Logout</button>
          </div>
        </Grid>
      </>}
    </Grid>
  </div>);
};
