import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import Billing from '../../components/billing/billing';
import LoteryAdmin from '../../components/lotery/loteryAdmin';
import Register from '../register/register';

import './admin.scss';
import MaxMount from '../../components/maxMount/maxMount';
import ChangePassword from '../../components/register/changePassword';
// import RestrictedNumbers from '../../components/restrictedNumbers/restrictedNumbers';

export default function ViewAdmin({ role, countryId }) {
  const [menus, setMenus] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  useEffect(() => {
    setMenus([
      { title: 'Mi Facturación', description: 'Aquí podras conocer tus números apostados', click: 'billding' },
      { title: 'Mis Usuarios', description: 'Aquí podras adminstrar tus usuarios registrados', click: 'register' },
      { title: 'Loterias', description: 'Aquí podras conocer las loterias y horarios vigentes', click: 'lotery' },
      { title: 'Cambiar Contraseña', description: 'Aquí podras cambiar tu contraseña y mas...', click: 'changePassword' },
      { title: 'Monto máximo por día', description: 'Aquí podras conocer cual es el monto maximo que se puede hacer por día', click: 'mountMax' },
      // { title: 'Números con restricciones', description: 'Aquí podras conocer cuales números tienen restriciones de monto por día', click: 'restrictedNumbers' }
    ])
  }, [])
  return <>
    <Grid container className='menu'>
      {menus.map((menu, index) => <Grid item
        xs={5} sm={4} md={3}
        key={'menu_' + index}
        className='menu_item'
        onClick={() => menu.click && setOpenModal(menu.click)}
      >
        <h3>{menu.title}</h3>
        <span>{menu.description}</span>
      </Grid>)}
    </Grid>

    <Dialog
      open={openModal !== false}
      onClose={handleClose}
      aria-describedby="modal-addUser"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick
      maxWidth={[''].includes(openModal) ? 'sm' : ['lotery'].includes(openModal) ? 'lg' : ['billding', 'register'].includes(openModal) ? 'md' : 'xs'}
      style={{ zIndex: 1000 }}
    >
      <DialogContent>
        {openModal === 'register' && <div>
          <Register
            isSuperAdmin={role === 1}
            closeModal={handleClose}
            countryId={countryId}
          />
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        }
        {openModal === 'lotery' && <div>
          <LoteryAdmin isSuperAdmin={role === 1} />
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        }
        {openModal === 'billding' && <div>
          <Billing isAdmin={true} />
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        }
        {openModal === 'mountMax' && <div>
          <MaxMount isSuperAdmin={role === 1} closeModal={handleClose} />
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        }
        {openModal === 'changePassword' && <ChangePassword
          closeModal={handleClose}
        />
        }
        {/* {openModal === 'restrictedNumbers' && <div>
          <RestrictedNumbers
            isSuperAdmin={role === 1}
            closeModal={handleClose}
          />
          <div className="right">
            <button className='button' onClick={handleClose}>Cerrar</button>
          </div>
        </div>
        } */}
      </DialogContent>
    </Dialog>
  </>
} 