import React, { useState, useEffect, useCallback } from 'react'
import Swal from 'sweetalert2';
import { Grid, Collapse, CircularProgress } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { getSales } from '../../apis/sale';
import moment from 'moment';
import './billing.scss';
export default function Billing({ isAdmin }) {
  const [loading, setLoading] = useState(false);
  const [salesTransaction, setSalesTransaction] = useState([]);
  const [dataDashboard, setDataDashborad] = useState([]);
  const [collapseIdOpen, setCollapseIdOpen] = useState(null)
  const [dataForm, setDataForm] = useState({
    dateInit: moment().startOf(isAdmin ? 'week' : 'day').format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD')
  })
  const refresh = useCallback(() => {
    setCollapseIdOpen(null)
    setLoading(true)
    getSales({
      dateInit: moment(dataForm.dateInit).utc().format(),
      dateEnd: moment(dataForm.dateEnd).endOf('day').utc().format(),
    })
      .then((billing) => {
        setSalesTransaction(billing.sales)
        setDataDashborad([
          { label: 'Venta Bruta', value: +billing.dashboard?.vBruta, isCurrent: true, isVisible: true },
          { label: 'Venta Neta', value: !!isAdmin ? +billing.dashboard?.vBruta - +billing.dashboard?.vComisionFather : +billing.dashboard?.vBruta - +billing.dashboard?.vComision, isCurrent: true, isVisible: true },
          { label: 'Comisión Administrador', value: +billing.dashboard?.vComisionFather, isCurrent: true, isVisible: !!isAdmin },
          { label: 'Comisión Vendedor', value: +billing.dashboard?.vComision, isCurrent: true, isVisible: true },
        ])
      })
      .catch((e) => Swal.fire('Error', JSON.stringify(e, null, 1), 'error'))
      .finally(() => setLoading(false))
  }, [dataForm, isAdmin])

  const onChangeDate = (event) => {
    event.preventDefault();
    setDataForm({ ...dataForm, [event.target.name]: event.target.value })
  }
  useEffect(() => {
    refresh()
  }, [refresh])
  return <>
    <Grid container className='dashboard'>
      <div>Facturación</div>
      <Grid item
        xs={12} sm={12} md={12}
        className={'dashDates title'}
      >
        <div>
          <form name={'formBilling'}>
            <div>
              <input type="date" name="dateInit" id="dateInit" placeholder='Fecha Inicial' value={dataForm.dateInit || ''} onChange={(e) => onChangeDate(e)} />
              <label>Fecha Inicio</label>
            </div>
            <div>
              <input type="date" name="dateEnd" id="dateEnd" placeholder='Fecha Fin' value={dataForm.dateEnd || ''} onChange={(e) => onChangeDate(e)} />
              <label>Fecha Fin</label>
            </div>
          </form>
        </div>
        <div className='right'>
          <button onClick={refresh} className='button save'>Buscar / Actualizar</button>
        </div>
      </Grid>
      {dataDashboard.filter((item) => item.isVisible === true).map((dash, index) => <Grid item
        key={'dashdata_' + index}
        className={'dashData'}
        xs={8} sm={5} md={4}
      >
        <p><strong>{dash.isCurrent === true ? '$ ' + (dash.value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,') : dash.value}</strong></p>
        <label>{dash.label}</label>
      </Grid>
      )}
    </Grid>
    {salesTransaction.length > 0 ? <Grid container className='dashboard'>
      <Grid item
        xs={12} sm={12} md={12}
        className={'billing'}
      >
        <table>
          <thead>
            <tr>
              <th>Transacción</th>
              <th>Celular</th>
              {isAdmin && <th>Username</th>}
              <th>Fecha</th>
              <th>Venta bruta</th>
              {isAdmin && <th><div>Comisión</div><div>Administrador</div></th>}
              {isAdmin && <th><div>Comisión</div><div>Vendedor</div></th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {salesTransaction.map((st, index_st) => <>
              <tr className='billing_row'
                key={'billing_row' + index_st}
                onClick={() => setCollapseIdOpen(prev =>
                  prev === st.saleTransactionId ? false : st.saleTransactionId
                )}
              >
                <td>{st.saleTransactionId}</td>
                <td>{st.cel ? st.cel : 'No registrado'}</td>
                {isAdmin && <td>{st.username}</td>}
                <td className='nowrap'>
                  <div>{moment(st.createdAt).format('YYYY-MM-DD')}</div>
                  <div>{moment(st.createdAt).format('hh:mm:ss a')}</div>
                </td>
                <td className='nowrap'>$ {(+st.montoCalculado).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                {isAdmin && <>
                  <td className='nowrap'>{st.comisionFather}% - ${(+st.montoCalculado * (+st.comisionFather) / 100).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td className='nowrap'>{st.comision}% - ${((+st.montoCalculado * (+st.comisionFather) / 100) * (+st.comision) / 100).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  {/* <td className='nowrap'>{st.comisionFather}% - ${((+st.montoCalculado * (+st.comision) / 100) * (+st.comisionFather) / 100).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td> */}
                </>}
                <td>{collapseIdOpen === st.saleTransactionId ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</td>
              </tr>
              <tr>
                <td colSpan={isAdmin ? 8 : 6}>
                  <Collapse
                    in={collapseIdOpen === st.saleTransactionId}
                    className="table_inter"
                  >
                    <table>
                      <thead>
                        <tr>
                          <th rowSpan={2}>Venta</th>
                          <th rowSpan={2}>Sorteo</th>
                          {/* <th>Fecha Sorteo</th> */}
                          <th rowSpan={2}>Loteria</th>
                          <th colSpan={2}>Cuatro cifras</th>
                          <th colSpan={2}>Tres cifras</th>
                          <th rowSpan={2}>Venta</th>
                        </tr>
                        <tr>
                          <th>Número</th>
                          <th>Monto</th>
                          <th>Número</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {st.sales.map((sale, index_sales) => <tr
                          key={'index_sales' + index_sales}
                          className='billing_row_interno'
                        >
                          <td>{sale.saleId}</td>
                          <td>{sale.sorteoId}</td>
                          {/* <td>{sale.loteria_sorteo?.fecha}</td> */}
                          <td>{sale.loteria_sorteo?.loteria?.abreviacion}</td>
                          {!sale.monto ? <td colSpan={2}>-</td> : <>
                            <td>{("000" + sale.numero).slice(-4)}</td>
                            <td>{`$ ${(sale.monto).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}`}</td>
                          </>}
                          {!sale.montoCombinado ? <td colSpan={2}>-</td> : <>
                            <td>{("00" + sale.numero).slice(-3)}</td>
                            <td>{`$ ${(sale.montoCombinado).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}`}</td>
                          </>}
                          <td>$ {(+sale.monto + +sale.montoCombinado).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}</td>
                        </tr>)}
                      </tbody>
                    </table>
                  </Collapse>
                </td>
              </tr>
            </>
            )}
          </tbody>
        </table>
      </Grid>
    </Grid > : <div className='center'>
      {loading ? <>
        <h3>Cargando Facturación</h3>
        <CircularProgress />
      </> :
        <h3>No hay resultados de facturación</h3>
      }
    </div>
    }
  </>
}